const bluesky = `#
# The following variables are predefined:
# 
# node_input: (dict) The input data for the node
# user_id: (str) The user ID
# workflow_id: (str) The workflow ID
# run_id: (str) The run ID
# node_id: (str) The node ID
  

`;

export default bluesky;
